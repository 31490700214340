import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import { Loader } from "../components";
import { useChangeTheme } from "../hooks/useChangeTheme";
import Layout from "../Layout";
import Google from "../pages/Reports/Google";
import OrdersListOsi from "../pages/Orders/ordersosi";

const Login = lazy(() => import("../pages/Login"));
const AnalyticOverView = lazy(() => import("../pages/AnalyticOverView"));
const UserRoles = lazy(() => import("../pages/UserRoles"));
const ProductList = lazy(() => import("../pages/Products/ProductList"));
const Categories = lazy(() => import("../pages/Products/Categories"));
const Attributes = lazy(() => import("../pages/Products/Attributes"));
const Combinations = lazy(() => import("../pages/Products/Combinations"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Unauthorized = lazy(() => import("../pages/Unauthorized"));
const Coupon = lazy(() => import("../pages/Coupon"));
const Payment = lazy(() => import("../pages/Payment"));
const Deneme = lazy(() => import("../pages/Payment/Deneme"));
const CustomerList = lazy(() => import("../pages/Customers"));
const OrdersList = lazy(() => import("../pages/Orders"));
const CargoPriceList = lazy(() => import("../pages/CargoPrice"));
const Campaings = lazy(() => import("../pages/Campaings"));

function Routes() {
  useChangeTheme();

  const routeConfig = [
    {
      path: "/",
      component: Login,
    },
    {
      path: "/cargo-price",
      component: CargoPriceList,
    },
    {
      path: "/home",
      component: AnalyticOverView,
    },
    {
      path: "/coupon",
      component: Coupon,
    },
    {
      path: "/campaing",
      component: Campaings,
    },
    {
      path: "/payment",
      component: Payment,
    },
    {
      path: "/deneme",
      component: Deneme,
    }, {
      path: "/customers",
      component: CustomerList,
    },
    {
      path: "/orders",
      component: OrdersList,
    },
    {
      path: "/orders-osi",
      component: OrdersListOsi,
    },
    {
      path: "/reports",
      component: Google,
    },


    {
      path: "/system-parameters/user-roles",
      component: UserRoles,
    },
    {
      path: "/products/product-list",
      component: ProductList,
    },
    {
      path: "/products/categories",
      component: Categories,
    },
    {
      path: "/products/attributes",
      component: Attributes,
    },
    // {
    //   path: "/products/combinations",
    //   component: Combinations,
    // },
    {
      path: "analytics/overview",
      component: AnalyticOverView,
    },
    {
      path: "unauthorized",
      component: Unauthorized,
    },
    {
      path: "not-found",
      component: NotFound,
    },
  ];

  return (
    <Switcher>
      {routeConfig
        .filter((route) => route !== false && route !== undefined)
        .map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              <Suspense fallback={<Loader />}>
                {route.path !== "/" ? (
                  <Layout>
                    <route.component />
                  </Layout>
                ) : (
                  <route.component />
                )}
              </Suspense>
            }
          />
        ))}
      <Route
        exact
        path="*"
        element={
          <Suspense fallback={<Loader />}>
            <Navigate to="/not-found" />
          </Suspense>
        }
      />
    </Switcher>
  );
}

export default Routes;
