import { useMemo, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { ModalApprovemation } from "../../components";
import { toast } from "react-toastify";
import {
  ADD,
  CHECK,
  MINUS,
  PDF,
  SHOPPING,
  WARNING,
  PACKET,
  SMILE,
  CARGO,
  EDIT,
} from "../../data/icons";
import axios from "axios";
import config from "../../config/authentication";
import { CiGift } from "react-icons/ci";

const { REACT_APP_BASE_URL } = process.env;

const ProductListForm = ({ toggle, setItems, currentItem, setCurrentItem }) => {
  console.log(currentItem);
  const [isSending, setIsSending] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState(
    currentItem && currentItem.invoice_id
  );
  const [orderData, setOrderData] = useState(currentItem);
  const [paymentStatus, setPaymentStatus] = useState(
    orderData && orderData.payments.length > 0
      ? orderData.payments[0].payment_status
      : ""
  );
  const [orderStatus, setOrderStatus] = useState(
    orderData ? orderData.order_status : ""
  );
  const [orderDescription, setOrderDescription] = useState(
    orderData ? orderData.order_description : ""
  );
  console.log(orderDescription);
  const [cargo, setCargo] = useState({});
  const [editField, setEditField] = useState(null); // Düzenlenen alanı takip ediyoruz
  const [tempData, setTempData] = useState({}); // Geçici olarak tutulan veriler
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCancelModalOpenTwo, setIsCancelModalOpenTwo] = useState(false);

  const handleCancelOrder = () => {
    axios
      .post(`${REACT_APP_BASE_URL}perfume-order/order-canceled`, {
        order_id: orderData.order_id,
      })
      .then((response) => {
        console.log("Ödeme isteği başarıyla gönderildi:", response);
        toast.success("Ödeme isteği başarıyla gönderildi");

        setTimeout(() => {
          window.location.reload();
        }, 500); // 500ms bekleyip sayfayı yenile
      })
      .catch((error) => {
        console.log("Ödeme isteği gönderilemedi:", error);
        toast.error("Ödeme isteği gönderilemedi");
      });

    console.log("Sipariş iptal edildi.");
    setIsCancelModalOpen(false);
  };
  const handleCancelOrderTwo = () => {
    console.log("Sipariş isteği atıldı.");
    setIsCancelModalOpenTwo(false);

    console.log("Ödeme isteği gönderiliyor...");

    axios
      .post(`${REACT_APP_BASE_URL}perfume-order/add-payment`, {
        invoice_id: orderData.invoice_id,
      })
      .then((response) => {
        console.log("Ödeme isteği başarıyla gönderildi:", response);
        toast.success("Ödeme isteği başarıyla gönderildi");

        setTimeout(() => {
          window.location.reload();
        }, 500); // 500ms bekleyip sayfayı yenile
      })
      .catch((error) => {
        console.log("Ödeme isteği gönderilemedi:", error);
        toast.error("Ödeme isteği gönderilemedi");
      });
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isSuccess]);
  const formatDate = (dates) => {
    const tarih = new Date(dates);

    let gun = tarih.getDate();
    let ay = tarih.getMonth() + 1; // JavaScript'te aylar 0'dan başlar, bu yüzden +1 ekliyoruz
    let yil = tarih.getFullYear();
    let saat = tarih.getHours();
    let dakika = tarih.getMinutes();
    let saniye = tarih.getSeconds();

    gun = gun < 10 ? "0" + gun : gun;
    ay = ay < 10 ? "0" + ay : ay;
    saat = saat < 10 ? "0" + saat : saat;
    dakika = dakika < 10 ? "0" + dakika : dakika;
    saniye = saniye < 10 ? "0" + saniye : saniye;

    return (
      gun + "/" + ay + "/" + yil + " " + saat + ":" + dakika + ":" + saniye
    );
  };

  const handleOrderClick = (orderId) => {
    setExpandedOrderId((prevOrderId) =>
      prevOrderId === orderId ? null : orderId
    );
  };

  const updateSubmit = (e, status) => {
    e.preventDefault();
    const obj = {
      order_status: status,
      order_id: orderData.order_id,
    };
    axios
      .post(REACT_APP_BASE_URL + "perfume-order/update-order", obj, {
        headers: {
          xsfr: config.secretKey,
        },
      })
      .then((res) => {
        if (status == "Kargoya Hazır") {
          setCargo(currentItem.cargo);
        }
        console.log(res);
        toast("Başarıyla güncellendi.", {
          type: "success",
        });
        setIsSuccess("Başarıyla güncellendi.");
      })
      .catch((err) => {
        setIsError("İşlem gerçekleştirilemedi.");
      });
  };
  const handleEditClick = (field) => {
    setEditField(field);
    setTempData({ ...orderData }); // Geçici veri oluşturuyoruz
  };

  const handleInputChange = (e, field) => {
    setTempData({ ...tempData, [field]: e.target.value });
  };

  const handleSave = (field) => {
    const dataToUpdate = { [field]: tempData[field] };
    axios
      .post(REACT_APP_BASE_URL + "perfume-order/order-info-update", {
        ...dataToUpdate,
        order_id: orderData.order_id,
      })
      .then(() => {
        setOrderData({ ...orderData, [field]: tempData[field] });
        toast.success("Başarıyla güncellendi");
        setEditField(null); // Düzenleme modundan çıkış
      })
      .catch(() => {
        toast.error("Güncelleme başarısız");
      });
  };

  const updateSubmitPayment = (e) => {
    e.preventDefault();
    const obj = {
      order_id: orderData.order_id,
      payment_status: "Havale İle Ödeme Alındı",
    };
    axios
      .post(REACT_APP_BASE_URL + "perfume-order/update-order", obj, {
        headers: {
          xsfr: config.secretKey,
        },
      })
      .then((res) => {
        toast("Başarıyla güncellendi.", {
          type: "success",
        });
        setIsSuccess("Başarıyla güncellendi.");
      })
      .catch((err) => {
        setIsError("İşlem gerçekleştirilemedi.");
      });
  };

  const funButtonView = (order_status) => {
    if (order_status == "Sipariş Beklemede") {
      return (
        <button className="btn btn-warning disabled">
          <Icon icon={WARNING} /> Ödeme Kontrolü Bekleniyor
        </button>
      );
    } else if (order_status == "Sipariş Alındı") {
      return (
        <button
          className="btn btn-success"
          onClick={(e) => updateSubmit(e, "Sipariş Hazırlanıyor")}
        >
          <Icon icon={SHOPPING} /> Sipariş Hazırlanıyor Olarak İşaretle
        </button>
      );
    } else if (order_status == "Sipariş Hazırlanıyor") {
      return (
        <button
          className="btn btn-success"
          onClick={(e) => updateSubmit(e, "Kargoya Hazır")}
        >
          <Icon icon={PACKET} /> Kargoya Hazır Olarak İşaretle
        </button>
      );
    } else if (order_status == "Kargoya Hazır") {
      return (
        <button
          className="btn btn-success"
          onClick={(e) => updateSubmit(e, "Kargoya Verildi")}
        >
          <Icon icon={CARGO} /> Kargoya Verildi Olarak İşaretle
        </button>
      );
    } else if (order_status == "Kargoya Verildi") {
      return (
        <button
          className="btn btn-success "
          onClick={(e) => updateSubmit(e, "Ürün Teslim Edildi")}
        >
          <Icon icon={SMILE} /> Ürün Teslim Edildi Olarak İşaretle
        </button>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {orderData && (
        <>
          <div className="h5">Sipariş Detayları</div>
          <p>E-mail: {orderData.user.email}</p>
          <p>
            Ad Soyad: {orderData.user.first_name} {orderData.user.last_name}
          </p>
          <p>Telefon Numarası: {orderData.user.phone_number}</p>
          <div className="h5">Siparişler</div>

          <table className="table">
            <thead>
              <tr>
                <th>Sipariş ID</th>
                <th>Sipariş Tarihi</th>
                <th>Sipariş Durumu</th>
                <th>Sipariş Tutarı</th>
              </tr>
            </thead>
            <tbody>
              <tr
                className="cursor-pointer"
                // onClick={() => handleOrderClick(orderData.invoice_id)}
              >
                <td>
                  <p className="d-flex">
                    <button
                      className="btn p-0"
                      style={{ border: "1px solid", borderRadius: "10px" }}
                      onClick={() => handleOrderClick(orderData.invoice_id)}
                    >
                      {expandedOrderId === orderData.invoice_id ? (
                        <Icon icon={MINUS} />
                      ) : (
                        <Icon icon={ADD} />
                      )}
                    </button>
                    &nbsp;{orderData.invoice_id}
                  </p>
                </td>
                <td>{formatDate(orderData.createdAt)}</td>
                <td>
                  {orderData.order_status == "Sipariş İşlemi Başarısız" ? (
                    <span className="text-danger">
                      Sipariş İşlemi Başarısız
                    </span>
                  ) : orderData.order_status == "Ürün Teslim Edildi" ? (
                    <span className="text-success">Ürün Teslim Edildi</span>
                  ) : (
                    <span className="text-warning">
                      {orderData.order_status}
                    </span>
                  )}
                </td>
                <td>{orderData.total_amount}₺</td>
              </tr>
              {expandedOrderId === orderData.invoice_id && (
                <tr>
                  <td colSpan={5} style={{ whiteSpace: "normal" }}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-6">
                          <b>Sipariş Adresi</b>
                          <div>
                            <b>Adres Satırı 1:</b> &nbsp;
                            {editField === "shipping_address_area1" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.shipping_address_area1}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "shipping_address_area1"
                                    )
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("shipping_address_area1")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.shipping_address_area1}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("shipping_address_area1")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>Adres Satırı 2:</b> &nbsp;
                            {editField === "shipping_address_area2" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.shipping_address_area2}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "shipping_address_area1"
                                    )
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("shipping_address_area2")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.shipping_address_area2}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("shipping_address_area2")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>Posta Kodu:</b> &nbsp;
                            {editField === "shipping_postcode" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.shipping_postcode}
                                  onChange={(e) =>
                                    handleInputChange(e, "shipping_postcode")
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("shipping_postcode")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.shipping_postcode}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("shipping_postcode")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>İlçe:</b> &nbsp;
                            {editField === "shipping_district" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.shipping_district}
                                  onChange={(e) =>
                                    handleInputChange(e, "shipping_district")
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("shipping_district")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.shipping_district}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("shipping_district")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>İl:</b> &nbsp;
                            {editField === "shipping_city" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.shipping_city}
                                  onChange={(e) =>
                                    handleInputChange(e, "shipping_city")
                                  }
                                />
                                <button
                                  onClick={() => handleSave("shipping_city")}
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.shipping_city}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("shipping_city")
                                  }
                                />
                              </>
                            )}
                          </div>
                          {orderData.phone_number && (
                            <div>
                              <b>Telefon:</b> &nbsp;
                              {editField === "phone_number" ? (
                                <>
                                  <input
                                    type="text"
                                    value={tempData.phone_number}
                                    onChange={(e) =>
                                      handleInputChange(e, "phone_number")
                                    }
                                  />
                                  <button
                                    onClick={() => handleSave("phone_number")}
                                  >
                                    Kaydet
                                  </button>
                                </>
                              ) : (
                                <>
                                  {orderData.phone_number}{" "}
                                  <Icon
                                    icon={EDIT}
                                    onClick={() =>
                                      handleEditClick("phone_number")
                                    }
                                  />
                                </>
                              )}
                            </div>
                          )}

                          <p></p>
                          <b>Fatura Adresi</b>
                          <div>
                            <b>Adres Satırı 1:</b> &nbsp;
                            {editField === "billing_address_area1" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.billing_address_area1}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "billing_address_area1"
                                    )
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("billing_address_area1")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.billing_address_area1}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("billing_address_area1")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>Adres Satırı 2:</b> &nbsp;
                            {editField === "billing_address_area2" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.billing_address_area2}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "billing_address_area2"
                                    )
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("billing_address_area2")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.billing_address_area2}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("billing_address_area2")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>Posta Kodu:</b> &nbsp;
                            {editField === "billing_postcode" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.billing_postcode}
                                  onChange={(e) =>
                                    handleInputChange(e, "billing_postcode")
                                  }
                                />
                                <button
                                  onClick={() => handleSave("billing_postcode")}
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.billing_postcode}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("billing_postcode")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>İlçe:</b> &nbsp;
                            {editField === "billing_district" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.billing_district}
                                  onChange={(e) =>
                                    handleInputChange(e, "billing_district")
                                  }
                                />
                                <button
                                  onClick={() => handleSave("billing_district")}
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.billing_district}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("billing_district")
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <b>İl:</b> &nbsp;
                            {editField === "billing_city" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.billing_city}
                                  onChange={(e) =>
                                    handleInputChange(e, "billing_city")
                                  }
                                />
                                <button
                                  onClick={() => handleSave("billing_city")}
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.billing_city}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("billing_city")
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <b>Sepet İçeriği</b>
                          <table className="table">
                            <tbody>
                              {orderData.cart.comb_atts.length > 0
                                ? orderData.cart.comb_atts.map((item2) => (
                                    <tr>
                                      <td>
                                        {item2.product.product_name}
                                        <br />(
                                        {
                                          item2.attribute_value.value_name
                                        } X {item2.cart_details.quantity} Adet)
                                      </td>
                                      <td>
                                        {item2.comb_price.price *
                                          item2.cart_details.quantity}
                                        ₺
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>

                          <div className="d-flex justify-content-between align-items-center">
                            {" "}
                            {funButtonView(orderData.order_status)}
                            <p className="h6">
                              {orderData.coupon && (
                                <>
                                  Kupon({orderData.coupon.coupon_code}):{" "}
                                  {orderData.coupon.coupon_value}
                                  {orderData.coupon.coupon_type}
                                  <br />
                                </>
                              )}
                              Kargo : {orderData.cargo_price}₺<br />
                              <br />
                              Toplam Ödenen Ücret: {orderData.total_amount}₺
                            </p>
                          </div>

                          <br />
                          <p className="h6 d-flex align-items-center gap-2">
                            Siparişe Ek Gönderilecek Kampanya Ürünü <CiGift />
                          </p>
                          <div className="d-flex gap-2 align-items-center">
                            {orderData.product ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_TEMP_URL}${orderData.product.product_images[0].image_url}`}
                                  crossOrigin="anonymous"
                                  alt=""
                                  style={{ width: "50px" }}
                                />
                                <p className="p-0 m-0">
                                  {orderData.product.product_name}/
                                  {
                                    orderData.product.product_images[0]
                                      .image_attribute
                                  }
                                </p>
                              </>
                            ) : (
                              <p>Kampanya dahili ürünü bulunamadı</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {orderData.payments.length > 0 && (
                          <div className="col-lg-4">
                            <b>Ödeme Yöntemi</b>
                            {orderData.payments.map((item2) => (
                              <>
                                {item2.credit_cart_no == "-" ? (
                                  <>
                                    <p>
                                      Ödeme Tipi : {orderData.order_description}
                                    </p>
                                    <p>Ödeme Durumu : {item2.payment_status}</p>
                                  </>
                                ) : (
                                  <>
                                    {item2.credit_cart_no && (
                                      <p>
                                        Kredi Kartı : {item2.credit_cart_no}
                                      </p>
                                    )}
                                    {item2.sipay_order_id && (
                                      <p>Sipay ID : {item2.sipay_order_id}</p>
                                    )}
                                    {item2.payment_description && (
                                      <p>
                                        Sipay Mesaj :{" "}
                                        {item2.payment_description}
                                      </p>
                                    )}
                                  </>
                                )}
                                {orderData.order_description == "Havale" &&
                                item2.payment_description ==
                                  "Ödeme Bekleniyor" ? (
                                  <button
                                    className="btn btn-success"
                                    onClick={(e) => updateSubmitPayment(e)}
                                  >
                                    <Icon icon={CHECK} />
                                    Ödeme Alındı Olarak İşaretle
                                  </button>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </div>
                        )}
                        {orderData.invoice_type && (
                          <div className="col-lg-4">
                            <b>Fatura Bilgileri </b>
                            {orderData.invoice_link && (
                              <a
                                style={{ fontSize: "30px" }}
                                href={
                                  REACT_APP_BASE_URL + orderData.invoice_link
                                }
                                target="__blank"
                              >
                                <Icon icon={PDF} />
                              </a>
                            )}
                            <br />
                            <b>Fatura Tipi:</b> &nbsp;
                            <>{orderData.invoice_type || "-"} </>
                            <br />
                            <b>Şirket Adı:</b> &nbsp;
                            {editField === "invoice_corporate_name" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.invoice_corporate_name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "invoice_corporate_name"
                                    )
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("invoice_corporate_name")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.invoice_corporate_name || "-"}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("invoice_corporate_name")
                                  }
                                />
                              </>
                            )}{" "}
                            <br />
                            <b>Vergi Dairesi:</b> &nbsp;
                            {editField === "invoice_tax_office" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.invoice_tax_office}
                                  onChange={(e) =>
                                    handleInputChange(e, "invoice_tax_office")
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("invoice_tax_office")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.invoice_tax_office || "-"}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("invoice_tax_office")
                                  }
                                />
                              </>
                            )}{" "}
                            <br />
                            <b>Vergi Numarası:</b> &nbsp;
                            {editField === "invoice_tax_number" ? (
                              <>
                                <input
                                  type="text"
                                  value={tempData.invoice_tax_number}
                                  onChange={(e) =>
                                    handleInputChange(e, "invoice_tax_number")
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleSave("invoice_tax_number")
                                  }
                                >
                                  Kaydet
                                </button>
                              </>
                            ) : (
                              <>
                                {orderData.invoice_tax_number || "-"}{" "}
                                <Icon
                                  icon={EDIT}
                                  onClick={() =>
                                    handleEditClick("invoice_tax_number")
                                  }
                                />
                              </>
                            )}
                            <p>
                              E-fatura Mükellefi:{" "}
                              {editField === "invoice_e_fatura" ? (
                                <>
                                  <select
                                    value={tempData.invoice_e_fatura}
                                    onChange={(e) =>
                                      handleInputChange(e, "invoice_e_fatura")
                                    }
                                  >
                                    <option value="true">Evet</option>
                                    <option value="false">Hayır</option>
                                  </select>
                                  <button
                                    onClick={() =>
                                      handleSave("invoice_e_fatura")
                                    }
                                  >
                                    Kaydet
                                  </button>
                                </>
                              ) : (
                                <>
                                  {orderData.invoice_e_fatura === "true"
                                    ? "Evet"
                                    : "Hayır"}{" "}
                                  <Icon
                                    icon={EDIT}
                                    onClick={() =>
                                      handleEditClick("invoice_e_fatura")
                                    }
                                  />
                                </>
                              )}
                            </p>
                          </div>
                        )}
                        {currentItem.cargo && (
                          <>
                            <div className="col-lg-4">
                              <b>Kargo Bilgileri </b>
                              {currentItem.cargo && (
                                <a
                                  style={{ fontSize: "30px" }}
                                  href={
                                    REACT_APP_BASE_URL +
                                    currentItem.cargo.cargo_file_url
                                  }
                                  target="__blank"
                                >
                                  <Icon icon={PDF} />
                                </a>
                              )}
                              <p>
                                Kargo Adı:{" "}
                                {currentItem.cargo.cargo_corporate_name || "-"}
                              </p>
                              <p>
                                Kargo Numarası:{" "}
                                {currentItem.cargo.cargo_tracking_number || "-"}
                              </p>
                              <p>
                                Kargo Takip Url:{" "}
                                {currentItem.cargo.cargo_tracking_url || "-"}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row col-2 d-flex gap-3">
                        <button
                          className="btn btn-danger"
                          onClick={() => setIsCancelModalOpen(true)} // Modal'ı açmak için state'i değiştiriyoruz
                        >
                          <Icon icon={WARNING} /> Siparişi İptal Et
                        </button>

                        {orderStatus === "Sipariş Beklemede" &&
                          orderDescription === "Kredi Kartı" && (
                            <button
                              className="btn btn-info"
                              onClick={() => setIsCancelModalOpenTwo(true)} // Modal'ı açmak için state'i değiştiriyoruz
                            >
                              <Icon icon={WARNING} /> Ödeme İsteği At
                            </button>
                          )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ModalApprovemation
            modal={isCancelModalOpen}
            toggle={() => setIsCancelModalOpen(!isCancelModalOpen)}
            title="Siparişi İptal Et"
            body="Bu siparişi iptal etmek istediğinize emin misiniz?"
            handleSubmit={handleCancelOrder}
            isSending={isSending}
            isError={isError}
            isSuccess={isSuccess}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
          <ModalApprovemation
            modal={isCancelModalOpenTwo}
            toggle={() => setIsCancelModalOpenTwo(!isCancelModalOpenTwo)}
            title="Tekrardan ödeme kontrolü için sipay'e istek at."
            body="Bu işlemden emin misiniz ?"
            handleSubmit={handleCancelOrderTwo}
            isSending={isSending}
            isError={isError}
            isSuccess={isSuccess}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
          <ModalApprovemation
            modal={Boolean(alertModal)}
            toggle={() => setAlertModal(!alertModal)}
            name={`Ürün Oluştur / Düzenle`}
            isSending={isSending}
            isError={isError}
            isSuccess={isSuccess}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
        </>
      )}
    </>
  );
};

export default ProductListForm;
