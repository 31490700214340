import { Icon } from "@iconify/react";
import { Button, Input } from "reactstrap";
import { INBOX, PDF, WORLD,PHONE } from "../../data/icons";
import { useState } from "react";
import config from "../../config/authentication";
import { Tooltip as ReactTooltip } from "react-tooltip";

const { REACT_APP_BASE_URL } = process.env;

const OrderRow = ({
  item,
  indexNo,
  setAlertModal,
  setCurrentItem,
  setIsDrawerOpen,
  setOrderId,
  handleSelectRow,
  selectedItems,
}) => {
  const {
    invoice_id,
    user,
    order_description,
    order_status,
    createdAt,
    order_id,
    payments,
    total_amount,
    order_form_link,
    cart,
    cargo_price,
    order_device  } = item;
  const [orderStatus, setOrderStatus] = useState(order_status);

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const formattedDate = `${day}.${month}.${year} ${hours}:${minute}`;

    return formattedDate;
  };

  const invoiceCreate = (e) => {
    e.preventDefault();
    setOrderId(order_id);
    setAlertModal(true);
  };

  return (
    <tr className="text-center">
      <td className="text-align-table">
        <Input
          type="checkbox"
          checked={selectedItems.some((selectedItem) => selectedItem.order_id === item.order_id)}
          onChange={(e) => handleSelectRow(e, item)}
        />
        
      </td>
      <td className="text-align-table">
    {order_form_link && <a
                color="success"
                target="__blank"
                className="mx-1 cursor-pointer"
                style={{fontSize:"1.1rem"}}
                href={REACT_APP_BASE_URL+order_form_link}
               
              >
                <Icon icon={PDF} />
              </a>}
      
      </td>
      <td className="text-align-table d-flex">
     
        {invoice_id}
    
      
        
        
      </td>
      <td style={{ textAlign: "left" }}>
        {user.first_name} {user.last_name}
      </td>
      <td className="text-align-table">{order_description} {order_device == "web" ? <Icon icon={WORLD} /> : <Icon icon={PHONE} />}</td>
      <td className="text-align-table">
        {orderStatus === "Sipariş İşlemi Başarısız" ? (
          <span className="text-danger">Sipariş İşlemi Başarısız</span>
        ) : orderStatus === "Ürün Teslim Edildi" ? (
          <span className="text-success">Ürün Teslim Edildi</span>
        ) : (
          <span className="text-warning">{orderStatus}</span>
        )}
      </td>
      {payments.length > 0 ? (
        <td className="text-align-table">
          {payments[0].payment_description === "Ödeme Alındı" ? (
            <span className="text-success text-center disabled">Ödeme Alındı</span>
          ) : (
            <span className="text-warning text-center disabled">Ödeme Bekleniyor</span>
          )}
        </td>
      ) :<td className="text-align-table"> 
      <span className="text-warning text-center disabled">Ödeme Bekleniyor</span>
      </td>}
      <td className="text-align-table"><span style={{ textAlign: "center" }} data-tooltip-id={order_id} className="mb-5">{total_amount.toFixed(2)}₺
       <br/> Kargo: {cargo_price == 0 ? "Ücretsiz" : cargo_price+'₺'}
        </span> 
      <ReactTooltip id={order_id} aria-haspopup='true' >

          <div className="p-3">
          {cart.comb_atts.map((item2) => {
            return (
              <p>{item2.product.product_name}<br />
                {item2.cart_details.quantity} Adet {item2.attribute_value.value_name} <br/>

                {item2.comb_price.price * item2.cart_details.quantity}₺
                <hr style={{border : "1px solid white"}}/>
              </p>
            )
          })}
         </div>
        </ReactTooltip>
      </td>

      <td className="text-align-table">{dateFormat(createdAt)}</td>
      <td className="text-align-table">
        <div className="d-flex justify-content-center">
          <Button
            color="success"
            onClick={() => {
              setCurrentItem(item);
              setIsDrawerOpen(true);
            }}
          >
            <Icon icon={INBOX} />
          </Button>
          {orderStatus !== "Sipariş İşlemi Başarısız" ? (
            item.invoice_link ? (
              <Button
                color="success"
                className="mx-1"
                onClick={() => {
                  window.open(REACT_APP_BASE_URL + item.invoice_link, "_blank");
                }}
              >
                <Icon icon={PDF} />
              </Button>
            ) : item.order_status === "Sipariş Beklemede" ? (
              <Button color="warning" className="mx-1" disabled>
                <Icon icon={PDF} />
              </Button>
            ) : (
              <Button color="dark" className="mx-1" onClick={invoiceCreate}>
                <Icon icon={PDF} />
              </Button>
            )
          ) : (
            <Button color="danger" className="mx-1" disabled>
              <Icon icon={PDF} />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default OrderRow;
