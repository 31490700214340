import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { SEARCH } from "../../data/icons";
import { Drawer, ModalApprovemation } from "../../components";
import OrderRow from "./OrdersRow";
import OrdersDetails from "./OrdersDetails";
import config from "../../config/authentication";
import { toast } from "react-toastify";
import { PDFDocument } from "pdf-lib";
const { REACT_APP_BASE_URL } = process.env;

function OrdersListOsi() {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [items, setItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20); // New state for items per page
  const [totalItemsCount, setTotalItemsCount] = useState(0); // New state for items per page
  const [message, setMessage] = useState(""); // New state for items per page
  const [fileExcell, setFileExcell] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectRow = (e, item) => {
    if (e.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) => selectedItem.order_id !== item.order_id
        )
      );
    }
  };

  const handleInputChange = async (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      setItems(originalItems);
      setMessage("");
      setTotalPages(Math.ceil(totalItemsCount / itemsPerPage));
    } else {
    }
  };

  const actionButton = async (e) => {
    if (e.target.value === "Kargoya Hazır") {
      try {
        const selectedResult = selectedItems.filter(
          (item) => item.order_status === "Sipariş Hazırlanıyor"
        );
        for (let i = 0; i < selectedResult.length; i++) {
          const item = selectedResult[i];
          const obj = {
            order_status: "Kargoya Hazır",
            order_id: item.order_id,
          };

          await axios.post(
            `${REACT_APP_BASE_URL}perfume-order/update-order`,
            obj,
            {
              headers: {
                xsfr: config.secretKey,
              },
            }
          );

          toast(
            `Sipariş ${item.user.first_name} ${item.user.last_name} başarıyla güncellendi.`,
            {
              type: "success",
            }
          );

          // 750ms gecikme
          await new Promise((resolve) => setTimeout(resolve, 750));
        }
      } catch (error) {
        toast("İşlem gerçekleştirilemedi.", {
          type: "error",
        });
      }
    } else if (e.target.value === "Elogo ile Fatura Gönder") {
      try {
        const selectedResult = selectedItems.filter(
          (item) => item.invoice_link == null
        );
        for (let i = 0; i < selectedResult.length; i++) {
          const item = selectedResult[i];
          const obj = {
            order_id: item.order_id,
          };
          toast(
            `Sipariş ${item.user.first_name} ${item.user.last_name} fatura oluşturuluyor.`,
            {
              type: "warning",
            }
          );

          await axios.post(`${REACT_APP_BASE_URL}xDdJsdEv-invoice/add`, obj, {
            headers: {
              xsfr: config.secretKey,
            },
          });

          toast(
            `Sipariş ${item.user.first_name} ${item.user.last_name} fatura oluşturuldu.`,
            {
              type: "success",
            }
          );

          // 750ms gecikme
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      } catch (error) {
        toast("İşlem gerçekleştirilemedi.", {
          type: "error",
        });
      }
    } else if (e.target.value === "Sipariş Formu Yazdır") {
      const selectedResult = selectedItems.filter(
        (item) => item.order_form_link
      );
      const pdfDoc = await PDFDocument.create();

      for (const selectedItem of selectedResult) {
        const url = REACT_APP_BASE_URL + selectedItem.order_form_link;
        try {
          const pdfBytes = await fetch(url)
            .then((res) => res.arrayBuffer())
            .catch((err) => {
              console.log("Error fetching PDF:", err);
              throw new Error("Failed to fetch PDF");
            });

          const pdf = await PDFDocument.load(pdfBytes);
          const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => pdfDoc.addPage(page));
        } catch (error) {
          console.error("Error processing PDF:", error);
        }
      }

      try {
        const mergedPdfBytes = await pdfDoc.save();
        console.log("Merged PDF size:", mergedPdfBytes.byteLength);

        // Save the merged PDF to the public folder
        const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "merger-order.pdf"; // specify the filename here
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("Merged PDF saved successfully.");
      } catch (error) {
        console.error("Error creating or saving merged PDF:", error);
      }
    }
  };

  const fetchData = async (pageNumber, itemsPerPage) => {
    try {
      const res = await axios.post(
        `${REACT_APP_BASE_URL}perfume-order/list-all-order-count`,
        {
          index: pageNumber - 1,
          limit: itemsPerPage, // Send the items per page to the backend
        },
        {
          headers: {
            xsfr: config.secretKey,
          },
        }
      );
      setItems(res.data.response);
      setOriginalItems(res.data.response);
      const totalItems = res.data.totalItems; // Assuming the API returns the total number of items
      setTotalItemsCount(res.data.totalItems);
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    } catch (error) {
      if (error.response?.status === 401) navigate("/unauthorized");
    }
  };

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchData(page, itemsPerPage);
  }, [page, itemsPerPage]);

  useEffect(() => {
    if (items.length === 0) {
      setFilteredData(
        <tr>
          <td className="text-warning">Bulunamadı...</td>
        </tr>
      );
    } else {
      setFilteredData(
        items.map((i, j) => (
          <OrderRow
            key={i.order_id}
            item={i}
            indexNo={j + 1}
            setAlertModal={setAlertModal}
            setIsDrawerOpen={setIsDrawerOpen}
            setCurrentItem={setCurrentItem}
            setOrderId={setOrderId}
            handleSelectRow={handleSelectRow}
            selectedItems={selectedItems}
          />
        ))
      );
    }
  }, [items, selectedItems]);

  const handleSubmitInvoiceCreate = (e) => {
    e.preventDefault();
    const obj = {
      order_id: orderId,
    };
    axios
      .post(`${REACT_APP_BASE_URL}xDdJsdEv-invoice/add`, obj, {
        headers: {
          xsfr: config.secretKey,
        },
      })
      .then((res) => {
        toast("Başarıyla fatura oluşturuldu.", {
          type: "success",
        });

        const updatedItems = items.map((item) =>
          item.order_id === orderId
            ? { ...item, invoice_link: res.data.invoice_link }
            : item
        );

        setItems(updatedItems);
        setAlertModal(false);
      })
      .catch((err) => {
        toast("İşlem gerçekleştirilemedi.", {
          type: "error",
        });
      });
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value)); // Update items per page
    setPage(1); // Reset to first page whenever items per page changes
  };
  const filterPendingPayments = () => {
    const filteredItems = originalItems.filter(
      (item) =>
        // item.payments.length === 0 &&
        item.order_status === "Sipariş Beklemede"
    );
    setItems(filteredItems);
    setTotalPages(Math.ceil(filteredItems.length / itemsPerPage));
  };

  useEffect(() => {
    if (items.length === 0) {
      setFilteredData(
        <tr>
          <td className="text-warning">Bulunamadı...</td>
        </tr>
      );
    } else {
      setFilteredData(
        items.map((i, j) => (
          <OrderRow
            key={i.order_id}
            item={i}
            indexNo={j + 1}
            setAlertModal={setAlertModal}
            setIsDrawerOpen={setIsDrawerOpen}
            setCurrentItem={setCurrentItem}
            setOrderId={setOrderId}
            handleSelectRow={handleSelectRow}
            selectedItems={selectedItems}
          />
        ))
      );
    }
  }, [items, selectedItems]);

  return (
    <main>
      <Container>
        <div className="h1">Osi Sipariş Listesi</div>
      </Container>

      <section>
        <Container>
          <Row>
            <Card>
              <CardBody>
                <div className="d-lg-flex d-md-flex align-items-center  gap-3">
                  <div className="col-lg-6 col-md-6 d-flex flex-wrap gap-3">
                    {" "}
                    <div className="d-flex align-items-center">
                      Limit:&nbsp;
                      <select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        className="form-control ml-2"
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                    <InputGroup className="outlined col-6">
                      <Input
                        type="text"
                        placeholder="Tabloda arama yapın.."
                        value={searchText}
                        onChange={handleInputChange}
                      />
                      <InputGroupText>
                        <Icon icon={SEARCH} width={20} />
                      </InputGroupText>
                    </InputGroup>
                    <Button
                      color="success"
                      className="text-nowrap"
                      onClick={async (e) => {
                        e.preventDefault();
                        const obj = { search: searchText };
                        const result = await axios.post(
                          `${REACT_APP_BASE_URL}perfume-order/order-search`,
                          obj,
                          {
                            headers: {
                              xsfr: config.secretKey,
                            },
                          }
                        );
                        console.log(result);
                        if (result.data.response.length > 0) {
                          setItems(result.data.response);
                          setTotalPages(
                            Math.ceil(result.data.totalItems / itemsPerPage)
                          );
                        } else {
                          setMessage("Sonuç bulunamadı...");
                        }
                      }}
                    >
                      <Icon icon={SEARCH} width={20} />
                      &nbsp;Ara
                    </Button>
                  </div>

                  <div className="col-lg-6 col-md-6 d-flex justify-content-lg-end justify-content-md-end mt-lg-0 mt-md-0 mt-3">
                    <Button
                      color={showAll ? "success" : "warning"}
                      style={{ width: "max-content" }}
                      onClick={async () => {
                        if (showAll) {
                          // Tümünü Göster butonuna tıklandığında, tüm siparişleri tekrar çekin
                          await fetchData(1, itemsPerPage); // İlk sayfayı ve varsayılan item sayısını çekin
                        } else {
                          // Ödeme bekleyenler filtrelensin
                          filterPendingPayments();
                        }
                        setShowAll(!showAll);
                      }}
                    >
                      {showAll ? "Tümünü Göster" : "Ödeme Bekleyenler"}
                    </Button>
                  </div>

                  {selectedItems.length > 0 && (
                    <>
                      <div className="col-3">
                        <select
                          type="select"
                          className="form-control col-5"
                          onChange={(e) => actionButton(e)}
                        >
                          <option>Aksiyonlar</option>
                          <option value="Kargoya Hazır">Kargoya Hazır</option>
                          <option value="Sipariş Formu Yazdır">
                            Sipariş Formu Yazdır
                          </option>
                          <option value="Elogo ile Fatura Gönder">
                            Elogo ile Fatura Gönder
                          </option>
                        </select>
                      </div>
                      <div className="col-2">
                        {selectedItems.length} Ürün Seçildi
                      </div>
                    </>
                  )}
                </div>

                <span className="text-warning">{message}</span>

                <br />
                <Table
                  hover
                  borderless
                  responsive
                  className="custom-table-bordered-head order"
                >
                  <thead className="text-center">
                    <tr>
                      <th
                        className="text-start text-align-table order"
                        style={{ minWidth: 50 }}
                      >
                        <Input
                          type="checkbox"
                          className="order-check"
                          checked={selectedItems.length === items.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th
                        style={{ minWidth: 50 }}
                        className="text-align-table"
                      ></th>
                      <th
                        style={{ minWidth: 100 }}
                        className="text-align-table"
                      >
                        Sipariş No
                      </th>
                      <th
                        style={{ minWidth: 100 }}
                        className="text-align-table"
                      >
                        Müşteri
                      </th>
                      <th
                        style={{ minWidth: 100 }}
                        className="text-align-table"
                      >
                        Ödeme Yöntemi
                      </th>
                      <th
                        style={{ minWidth: 100 }}
                        className="text-align-table"
                      >
                        Sipariş Durumu
                      </th>
                      <th
                        style={{ minWidth: 100 }}
                        className="text-align-table"
                      >
                        Ödeme Durumu
                      </th>
                      <th className="text-align-table">Toplam Tutar</th>
                      <th className="text-align-table">Sipariş Tarihi</th>
                      <th className="text-align-table">İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>{filteredData}</tbody>
                </Table>

                <Pagination>
  <PaginationItem disabled={page === 1}>
    <PaginationLink previous onClick={() => handlePageChange(page - 1)} />
  </PaginationItem>

  {page > 2 && (
    <>
      <PaginationItem>
        <PaginationLink onClick={() => handlePageChange(1)}>1</PaginationLink>
      </PaginationItem>
      {page > 3 && <PaginationItem disabled><PaginationLink>...</PaginationLink></PaginationItem>}
    </>
  )}

  {[...Array(totalPages)].map((_, i) => {
    const pageNum = i + 1;
    if (pageNum >= page - 1 && pageNum <= page + 1) {
      return (
        <PaginationItem active={pageNum === page} key={pageNum}>
          <PaginationLink onClick={() => handlePageChange(pageNum)}>
            {pageNum}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return null;
  })}

  {page < totalPages - 1 && (
    <>
      {page < totalPages - 2 && <PaginationItem disabled><PaginationLink>...</PaginationLink></PaginationItem>}
      <PaginationItem>
        <PaginationLink onClick={() => handlePageChange(totalPages)}>{totalPages}</PaginationLink>
      </PaginationItem>
    </>
  )}

  <PaginationItem disabled={page === totalPages}>
    <PaginationLink next onClick={() => handlePageChange(page + 1)} />
  </PaginationItem>
</Pagination>

              </CardBody>
            </Card>
          </Row>
        </Container>
      </section>
      <Drawer
        className="lg"
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      >
        <OrdersDetails
          setItems={setItems}
          toggle={toggle}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
        />
      </Drawer>
      <ModalApprovemation
        modal={Boolean(alertModal)}
        toggle={() => setAlertModal(!alertModal)}
        name={`Ürün Listesi`}
        body={`Faturayı oluşturmak istediğini emin misiniz ?`}
        handleSubmit={handleSubmitInvoiceCreate}
      />
    </main>
  );
}

export default OrdersListOsi;
